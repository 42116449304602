// extracted by mini-css-extract-plugin
export var detailsBtn = "PricingTier-module--detailsBtn--edb49";
export var discountPrice = "PricingTier-module--discountPrice--8d742";
export var discountWrapper = "PricingTier-module--discountWrapper--b593a";
export var featuresHeader = "PricingTier-module--featuresHeader--108ef";
export var hasVoucher = "PricingTier-module--hasVoucher--e30be";
export var originalPrice = "PricingTier-module--originalPrice--906ff";
export var popContainer = "PricingTier-module--popContainer--f85da";
export var priceContainer = "PricingTier-module--priceContainer--1d58c";
export var priceZoneContainer = "PricingTier-module--priceZoneContainer--94e0f";
export var pricingTierSwitchWrapper = "PricingTier-module--pricingTierSwitchWrapper--aff26";
export var tierBtn = "PricingTier-module--tierBtn--9cd5f";
export var tierButtons = "PricingTier-module--tierButtons--3f0b1";
export var tierContainer = "PricingTier-module--tierContainer--ffb22";
export var tierFeatures = "PricingTier-module--tierFeatures--37569";
export var tierName = "PricingTier-module--tierName--0d630";
export var tierOption = "PricingTier-module--tierOption--16903";
export var trialDescription = "PricingTier-module--trialDescription--392f7";
export var voucherAppliedContainer = "PricingTier-module--voucherAppliedContainer--4c165";
export var voucherPrice = "PricingTier-module--voucherPrice--abf2a";
export var voucherText = "PricingTier-module--voucherText--3ed4c";