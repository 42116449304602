import React, { useContext, useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import cn from 'classnames';
import { createTrackingEvent } from "lib/tracking";
import { Button } from "@soundtrackyourbrand/ui/src/Button";
import * as styles from "./PricingTier.module.scss";
import PropTypes from "prop-types";
import StructuredTextRenderer from "../../StructuredTextRenderer/StructuredTextRenderer";
import { PricingContext } from "../../Layout/Layout";
import Personalization from "../../Personalization/Personalization";

const PricingTier = ({
  pricingTier,
  isYearlyBilling,
  handleChangeIsYearlyBilling
}) => {
  //Track pricing tier signup click

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          signupUrl
        }
      }
    }
  `);

  const tierBtn = pricingTier.tierButton[0];
  const pricePlan = pricingTier.pricePlan;
  const isEnterprisePricePlan = pricePlan === "enterprise";

  const { pricing } = useContext(PricingContext);

  const [showDetails, setShowDetails] = useState(false);

  // Construct sign up URL so business knows what tier and billing cycle was selected
  const signUpUrl =
    data.site.siteMetadata.signupUrl +
    "?billing-cycle=" +
    (isYearlyBilling ? "yearly" : "monthly");

  // Enterprise uses internal link from Dato
  const internalLink = `/${tierBtn.slug?.slug}`;

  const hasTier = pricing.hasPricePlan(pricePlan);

  const pricesLoading = pricing.prices.loading;

  return (
    <div className={cn('col sm-12 l-4', styles.tierContainer, styles.popContainer)}>
      <div
        className={styles.tierOption}
      >
        <div className={`text6 mb-6 ${styles.tierName}`}>
          {(pricingTier.name === 'Essential' || pricingTier.name === 'Unlimited') && <p className="mb-0">Soundtrack</p>}
          <p className="mb-0">{pricingTier.name}</p>
        </div>
        <p className="mb-7">{pricingTier.tierDescription}</p>
        <div className="hide-to-s">
          <StructuredTextRenderer
            data={pricingTier.featureListHeader}
            isParagraphClassName={`mb-3 text3 ${styles.featuresHeader}`}
          />
          {pricingTier.features && (
            <ul className={`text3 ${styles.tierFeatures}`}>
              {pricingTier.features.map((feature) => (
                <Personalization
                  key={feature.id}
                  visibleFor={feature.visibleFor}
                >
                  <li className="mb-3">
                    <p className="mb-0">{feature.title}</p>
                    <p className="subtle mb-0">{feature.subtitle}</p>
                  </li>
                </Personalization>
              ))}
            </ul>
          )}
        </div>
        {showDetails && (
          <div className="hide-from-s">
            <StructuredTextRenderer
              data={pricingTier.featureListHeader}
              isParagraphClassName={`mb-3 text3 ${styles.featuresHeader}`}
            />
            {pricingTier.features && (
              <ul className={`text3 ${styles.tierFeatures}`}>
                {pricingTier.features.map((feature) => (
                  <li key={feature.id} className="mb-3">
                    <p className="mb-0">{feature.title}</p>
                    <p className="subtle mb-0">{feature.subtitle}</p>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
        {!isEnterprisePricePlan && (pricesLoading || hasTier) ? (
          <>
            <div className={styles.priceContainer}>
              {isYearlyBilling ? (
                <>
                  <div className={styles.discountWrapper}>
                    <div className={styles.originalPrice}>
                      <p className="text3 mb-2">Original</p>
                      <p className="text6 mb-0 lineThrough">
                        {pricing.renderPrice(
                          pricePlan,
                          pricing.PRICING_PROPERTIES.MONTHLY
                        )}
                      </p>
                    </div>
                    <div className={styles.voucherPrice}>
                      <p className="text3 mb-2">Offer</p>
                      <p className="text5 mb-0">
                        {pricing.renderPrice(
                          pricePlan,
                          pricing.PRICING_PROPERTIES.YEARLY_PER_MONTH
                        )}
                      </p>
                    </div>
                  </div>
                  <p className={styles.priceZoneContainer}>
                    / per zone and month
                  </p>
                  <p
                    className={`text5 mb-1 ${styles.discountPrice}`}
                  >
                    {pricing.renderPrice(
                      pricePlan,
                      pricing.PRICING_PROPERTIES.YEARLY
                    )}
                  </p>
                  <p className={styles.priceZoneContainer}>
                    Total yearly cost due after free trial
                  </p>
                </>
              ) : (
                <>
                  <p className="text6 mb-0">
                    {pricing.renderPrice(
                      pricePlan,
                      pricing.PRICING_PROPERTIES.MONTHLY
                    )}
                  </p>
                  <p className={styles.priceZoneContainer}>
                    / per <span>zone</span> and month
                  </p>

                </>
              )}
            </div>
            <div
              role="button"
              tabIndex={0}
              className={`mb-7 ${styles.pricingTierSwitchWrapper}`}
              onClick={() => {
                handleChangeIsYearlyBilling();
                createTrackingEvent(
                  "Storefront - Pricing - Changed Billing Cycle",
                  {},
                  "Change",
                  "Change billing Cycle"
                );
              }}
              onKeyDown={null}
            >
              <span className={isYearlyBilling ? 'subtle' : ''} >Bill monthly</span>
              <input
                type="checkbox"
                className="switch"
                disabled={pricesLoading}
                onChange={handleChangeIsYearlyBilling}
                checked={isYearlyBilling}
              />
              <span className={isYearlyBilling ? '' : 'subtle'}  >Bill annually</span>
            </div>
          </>
        ) : (
          pricingTier.optionalText && <p className="text6 mb-7">{pricingTier.optionalText}</p>
        )}
        <div className={`mb-6 flex ${styles.tierButtons}`}>
          {pricingTier.features && (
            <div className={`hide-from-s`}>
              <Button
                className={cn(styles.detailsBtn, styles.tierBtn)}
                onClick={() => setShowDetails((showDetails) => !showDetails)}
                destructive
              >
                {showDetails ? "hide details" : "see details"}
              </Button>
            </div>
          )}
          {isEnterprisePricePlan
            ? (
              <Button
                primary
                tag={Link}
                to={internalLink}
                className={cn('mb-0', styles.tierBtn)}
                onClick={() => {
                  createTrackingEvent(
                    `Storefront - Click Signup CTA ${pricingTier.name}`,
                    {},
                    "Click",
                    `Click Signup CTA ${pricingTier.name}`,
                    pricingTier.name
                  );
                }}
              >
                {tierBtn.text}
              </Button>
            )
            : (pricesLoading || hasTier) && (
              <Button
                primary
                loading={pricesLoading}
                tag="a"
                href={signUpUrl}
                className={cn('mb-0', styles.tierBtn)}
                onClick={() => {
                  createTrackingEvent(
                    `Storefront - Click Signup CTA ${pricingTier.name}`,
                    {},
                    "Click",
                    `Click Signup CTA ${pricingTier.name}`,
                    pricingTier.name
                  );
                }}
              >
                {tierBtn.text}
              </Button>
            )}
        </div>
        {pricesLoading || isEnterprisePricePlan || hasTier ? (
          pricingTier.trialInformation && (
            <StructuredTextRenderer
              data={pricingTier.trialInformation}
              isParagraphClassName={`mb-0 subtle ${styles.trialDescription}`}
            />
          )
        ) : (
          <p>This tier is not available in your country.</p>
        )}
      </div>
    </div>
  );
};

export default PricingTier;

PricingTier.propTypes = {
  isYearlyBilling: PropTypes.bool.isRequired,
  handleChangeIsYearlyBilling: PropTypes.func.isRequired,
  pricingTier: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    tierDescription: PropTypes.string.isRequired,
    featureListHeader: PropTypes.object.isRequired,
    features: PropTypes.array.isRequired,
    tierButton: PropTypes.array.isRequired,
    trialInformation: PropTypes.object,
    pricePlan: PropTypes.string.isRequired,
  }).isRequired,
};
