import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { createTrackingEvent } from "lib/tracking";
import cn from 'classnames';
import { Section } from "@soundtrackyourbrand/ui/src/Section";
import { Button } from "@soundtrackyourbrand/ui/src/Button";

import PricingTier from "./PricingTier";
import PropTypes from "prop-types";
import * as styles from "./Pricing.module.scss";

const Pricing = ({ block }) => {
  const title = block.pricing.title;
  const pricingTiers = block.pricing.pricingTiers;
  const pricingButton = block.pricing.pricingButton[0];

  const [isYearlyBilling, setIsYearlyBilling] = useState(false);

  const handleChangeIsYearlyBilling = () => {
    setIsYearlyBilling(!isYearlyBilling);
  };

  return (
    <Section className={cn(styles.pricingContainer, styles.popContainer)}>
      {title && (
        <h2 className="text8">{title}</h2>
      )}
      <div className={`row ${styles.pricingTiers}`}>
        {pricingTiers.map((pricingTier) => (
          <PricingTier
            key={pricingTier.id}
            pricingTier={pricingTier}
            isYearlyBilling={isYearlyBilling}
            handleChangeIsYearlyBilling={handleChangeIsYearlyBilling}
          />
        ))}
      </div>
      <div className={`flex r-right mt-7`}>
        <Button
          tag={Link}
          to={`/${pricingButton.slug.slug}`}
          className={`c-center mb-0 ${styles.demoBtnPOP}`}
          large={true}
          onClick={() => {
            createTrackingEvent(
              "Storefront - Click Book A Demo CTA",
              {},
              "Click",
              "Click Book A Demo CTA"
            );
          }}
        >
          {pricingButton.text}
        </Button>
      </div>
    </Section>
  );
};

export default Pricing;

Pricing.propTypes = {
  block: PropTypes.shape({
    pricing: PropTypes.shape({
      title: PropTypes.string,
      pricingButton: PropTypes.array.isRequired,
      pricingTiers: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          pricePlan: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          tierDescription: PropTypes.string.isRequired,
          featureListHeader: PropTypes.object.isRequired,
          features: PropTypes.array.isRequired,
          tierButton: PropTypes.array.isRequired,
          trialInformation: PropTypes.object,
        })
      ).isRequired,
    }).isRequired,
  }),
};

export const query = graphql`
  fragment PricingReference on DatoCmsPricingreference {
    model {
      apiKey
    }
    id: originalId
    pricing {
      title
      pricingTiers {
        id: originalId
        pricePlan
        name
        tierDescription
        featureListHeader {
          value
        }
        features {
          id: originalId
          title
          subtitle
          visibleFor
        }
        optionalText
        tierButton {
          ...InternalLink
        }
        trialInformation {
          value
        }
      }
      pricingButton {
        ...InternalLink
      }
    }
  }
`;
